import React, { useState, useRef, useEffect } from "react";
import MicrophoneOffIcon from "../src/assets/MicrophoneOffIcon.jsx";
import { MicrophoneOnIcon } from "../src/assets/MicrophoneOnIcon.jsx";
import { StopIcon } from "../src/assets/StopIcon.jsx";
import { EndCall } from "../src/assets/EndCall.jsx";
import Template from "./components/Template.jsx";

function App() {
  // const [inputText, setInputText] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isSelectTemplate, setIsSelectTemplate] = useState(false);
  const [template, setTemplate] = useState("tom");
  const [tooltip, setTooltip] = useState("");
  const [holdTime, setHoldTime] = useState(0);
  const avatarTemplate = useRef(null);
  const [sessionId, setSessionId] = useState(null);
  const recognitionRef = useRef(null);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const pc = useRef(null);
  const ws = useRef(null);
  const iceCandidatesQueue = useRef([]);
  const inputText = useRef(null);


  useEffect(() => {
    avatarTemplate.current = template;
  }, [template]);

const handleUpload = async (speechToText) => {
  // Close existing connections before starting a new one
  if (pc.current) {
      pc.current.close();
      pc.current = null;
  }
  if (ws.current) {
      ws.current.close();
      ws.current = null;
  }

  // Initialize WebSocket
  ws.current = new WebSocket(process.env.REACT_APP_VIDEO_STREAM);

  ws.current.onopen = () => {
      console.log('WebSocket connection established');
      // Initialize WebRTC Peer Connection
      const iceServers = [
          {
              urls: [process.env.REACT_APP_SERVER_URL],
              username: process.env.REACT_APP_USER_NAME,
              credential: process.env.REACT_APP_CREDENTIAL
          }
      ];

      pc.current = new RTCPeerConnection({
          iceServers: iceServers,
          iceTransportPolicy: 'relay'
      });
      console.log("WebRTC PeerConnection initialized with TURN server");

      // Add event listeners for debugging
      pc.current.oniceconnectionstatechange = () => {
          console.log(`ICE connection state: ${pc.current.iceConnectionState}`);
      };
      pc.current.onsignalingstatechange = () => {
          console.log('Signaling state:', pc.current.signalingState);
      };
      pc.current.onconnectionstatechange = () => {
          console.log('Connection state:', pc.current.connectionState);
          if (pc.current.connectionState === 'failed' || pc.current.connectionState === 'disconnected' || pc.current.connectionState === 'closed') {
              if (ws.current) {
                  ws.current.close();
                  ws.current = null;
              }
              setIsStreaming(false)
          }
      };

      // Handle incoming media streams
      pc.current.ontrack = (event) => {
          console.log("Received remote track:", event.track.kind);
          if (videoRef.current) {
              videoRef.current.srcObject = event.streams[0];
          }
      };

      // Handle ICE candidates from the local peer
      pc.current.onicecandidate = (event) => {
          if (event.candidate) {
              console.log('New local ICE candidate: ', event.candidate);
              ws.current.send(JSON.stringify({
                  type: 'candidate',
                  candidate: event.candidate.candidate,
                  sdpMid: event.candidate.sdpMid,
                  sdpMLineIndex: event.candidate.sdpMLineIndex
              }));
          }
      };

      // Add transceivers for audio and video
      pc.current.addTransceiver('video', { direction: 'recvonly' });
      pc.current.addTransceiver('audio', { direction: 'recvonly' });

      // Create an SDP offer
      pc.current.createOffer().then(offer => {
          return pc.current.setLocalDescription(offer);
      }).then(() => {
          console.log("SDP offer created and set as local description");
          // Send the offer to the server via WebSocket
          ws.current.send(JSON.stringify({
              type: pc.current.localDescription.type,
              sdp: pc.current.localDescription.sdp,
              input_text: inputText.current,
              gpt_response: true,
              avatar: avatarTemplate?.current,
              language: "english",
              api_key: process.env.REACT_APP_MASTER_KEY
          }));
      }).catch(e => console.error(e));
  };

  ws.current.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'answer') {
          // Set the remote description with the SDP answer from the server
          await pc.current.setRemoteDescription(new RTCSessionDescription(data));
          console.log("SDP answer received and set as remote description");
      } else if (data.type === 'candidate') {
          // Add the remote ICE candidate
          try {
              await pc.current.addIceCandidate(new RTCIceCandidate(data));
              console.log("Added remote ICE candidate:", data);
          } catch (e) {
              console.error("Error adding remote ICE candidate:", e);
          }
      }else if (data.stream_state === "ended") {
        setIsStreaming(!isStreaming);
    }
  };

  ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
  };

  ws.current.onclose = () => {
      console.log("WebSocket connection closed");
      if (pc.current) {
          pc.current.close();
          pc.current = null;
      }
  };
};

useEffect(() => {
  return () => {
      // Cleanup WebRTC connections when the component unmounts
      if (pc.current) {
          pc.current.close();
          pc.current = null;
      }
      if (ws.current) {
          ws.current.close();
          ws.current = null;
      }
  };
}, []);

  const handleStartListening = () => {
    if (isListening || isStreaming) return;
    if (!recognitionRef.current) {
      // Initialize speech recognition
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.lang = "en-US";
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;

      recognition.onresult = (event) => {
        const speechToText = event.results[0][0].transcript;
        console.log("Recognized text:", speechToText);
        // setInputText(speechToText);
        inputText.current = speechToText;
        setIsListening(false);
        handleUpload(speechToText);
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
      };

      recognition.onspeechend = () => {
        recognition.stop();
        recognitionRef.current = null; // Reset recognition reference
        setIsListening(false);
      };

      recognitionRef.current = recognition;
    }

    if (!isListening) {
      recognitionRef.current.start();
      setIsListening(true);
      setHoldTime(Date.now());
      console.log("Speech recognition started");
    } else {
      console.log("Speech recognition is already active");
    }
  };

  const handleSelectTemplate = (temp) => {
    setIsSelectTemplate(true);
    setTemplate(temp);
  };

  const handleCloseSession = () => {
    setIsSelectTemplate(false);
    setTemplate("");
    // setInputText("");
    inputText.current=null;
    // Close the WebSocket connection if it exists
    if (ws.current) {
      ws.current.close();
    }

    // Stop speech recognition if it's running
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };
  const handleStopAvatar = () => {
    if (ws.current) {
      ws.current.close();
    }
    if (audioRef && audioRef.current) {
      audioRef.current.pause();
      videoRef.current.srcObject = null;
    }
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };
  const handleMute = () => {
    if (recognitionRef?.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  useEffect(() => {
    if (videoRef.current && !isStreaming) {
      videoRef.current.load(); // Reload the video source when isStreaming changes
    }
  }, [isStreaming]);

  return (
    <div className="App">
      <div className="mobile-frame">
        {isSelectTemplate ? (
          <>
            <h1>Setoo AI Avatar</h1>
            <p>{isListening ? "Listening..." : inputText.current}</p>
            <br />
            <video
      ref={videoRef}
      autoPlay
      playsInline
      loop={!isStreaming}
      poster={`./input_${template}.gif`}
      key={isStreaming} // Reset video element when isStreaming changes
    >
      {!isStreaming && (
        <source
          src={require(`./input_${template}.mp4`)}
          type="video/mp4"
        />
      )}
    </video>
            <audio ref={audioRef} hidden controls />
            <div className="button-container">
              <div className="tooltip-container">
                {isListening ? (
                  <button
                    className="mute"
                    onClick={handleMute}
                    onMouseEnter={() => setTooltip("Mute")}
                    onMouseLeave={() => setTooltip("")}
                  >
                    <MicrophoneOnIcon />
                  </button>
                ) : (
                  <button
                    className="un_mute"
                    onClick={handleStartListening}
                    onMouseEnter={() => setTooltip("Unmute")}
                    onMouseLeave={() => setTooltip("")}
                  >
                    <MicrophoneOffIcon />
                  </button>
                )}
                {tooltip && <span className="tooltip">{tooltip}</span>}
              </div>
              <div className="tooltip-container">
                <button
                  onMouseEnter={() => setTooltip("Stop Talking")}
                  onMouseLeave={() => setTooltip("")}
                  onClick={handleStopAvatar}
                >
                  <StopIcon />
                </button>
                {tooltip && <span className="tooltip">{tooltip}</span>}
              </div>
              <div className="tooltip-container">
                <button
                  onClick={handleCloseSession}
                  onMouseEnter={() => setTooltip("End Session")}
                  onMouseLeave={() => setTooltip("")}
                >
                  <EndCall />
                </button>
                {tooltip && <span className="tooltip">{tooltip}</span>}
              </div>
            </div>
          </>
        ) : (
          <Template handleSelectTemplate={handleSelectTemplate} />
        )}
      </div>
    </div>
  );
}

export default App;
