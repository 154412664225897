import React from 'react';

const MicrophoneOffIcon = () => (
  <svg
    fill="#FFF"
    height="30"
    width="30"
    viewBox="0 0 420 420"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M148.4,206.8c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4l50.8-50.8l50.8,50.8c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4
           c3.2-3.2,3.2-8,0-11.2L222,144.4l50.8-50.8c3.2-3.2,3.2-8,0-11.2c-3.2-3.2-8-3.2-11.2,0l-51.2,50.8l-50.8-50.8
           c-3.2-3.2-8-3.2-11.2,0c-3.2,3.2-3.2,8,0,11.2l50.8,50.8l-50.8,51.2C145.2,198.8,145.2,203.6,148.4,206.8z"
      />
      <path
        d="M210,304c57.2,0,104-46.8,104-104v-96C314,46.8,267.2,0,210,0S106,46.8,106,104v96C106,257.2,152.8,304,210,304z
            M122,104c0-48.4,39.6-88,88-88c48.4,0,88,39.6,88,88v96c0,48.4-39.6,88-88,88c-48.4,0-88-39.6-88-88V104z"
      />
      <path
        d="M354,180c-4.4,0-8,3.6-8,8v16c0,72.8-59.2,132-132,132h-8c-72.8,0-132-59.2-132-132v-16c0-4.4-3.6-8-8-8s-8,3.6-8,8v16
           c0,80.4,64.4,145.6,144,148v60c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8v-60c79.6-2,144-67.6,144-148v-16C362,183.6,358.4,180,354,180z"
      />
    </g>
  </svg>
);

export default MicrophoneOffIcon;
