import React from 'react'

function Template({handleSelectTemplate}) {
  return (
    <div className="image-container">
         <div className="image-item" onClick={()=>handleSelectTemplate("felicity")}>
        <img src="/felicity.png" alt="felicity" />
        <p>Talk to Felicity</p>
    </div>
    <div className="image-item" onClick={()=>handleSelectTemplate("tom")}>
        <img src="/tom.png" alt="tom" />
        <p>Talk to Tom</p>
    </div>
    <div className="image-item" onClick={()=>handleSelectTemplate("angelina")}>
        <img src="/angelina.png" alt="angelina" />
        <p>Talk to Angelina</p>
    </div>
    <div className="image-item" onClick={()=>handleSelectTemplate("varun")}>
        <img src="/varun.png" alt="varun" />
        <p>Talk to Varun</p>
    </div>
    <div className="image-item" onClick={()=>handleSelectTemplate("celina")}>
        <img src="/celina.png" alt="celina" />
        <p>Talk to Celina</p>
    </div>
    <div className="image-item" onClick={()=>handleSelectTemplate("oliver")}>
        <img src="/oliver.png" alt="oliver" />
        <p>Talk to Oliver</p>
    </div>
</div>

  )
}

export default Template